import React from 'react';
import { render } from 'react-dom';

class PageNoteFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h1>
                                Oops!</h1>
                            <h2>
                                404 Not Found</h2>
                            <div className="error-details">
                                Sorry, an error has occured, Requested page not found!
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNoteFound;