import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import PageTemplatePreview from "./PageTemplatePreview";
import PageNotFound from "./PageNotFound";
import { Route } from 'react-router-dom';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <HashRouter>
        <div>
          <Switch>
            <Route path='/:decodeuserinfo/:suffix' component={PageTemplatePreview} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </HashRouter>
    )

  }
}

export default App;