export const constants = {

    //Production Server
    endPoint: "https://apiv2.soffront.com/",
    landingPageEndPoint: 'https://pages.soffront.com/#',

    //Test Server
    //endPoint: "https://testapiv2vpc.soffront.com/",
    //landingPageEndPoint: 'https://testpages.soffront.com/#',

    //Local
    //endPoint: "http://localhost:9080/sfapiv2/",
    //landingPageEndPoint: 'http://localhost:3000/#'
}

export const endPoint = {
    publicfaceURL: constants.endPoint + '/v3/landingpage/publicface/get',
    getRedirectURL: constants.endPoint + '/v3/landingpage/get/redirect/url'
}