import React from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import { constants, endPoint } from "./constants";
import PageNotFound from "./PageNotFound";

class PageTemplatePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            body: "",
            projectId: 0,
            userId: 0,
            landingPageId: 0,
            isValidURL: true,
            redirectURL: ''
        }
    }

    componentDidMount() { debugger
        this.getLandingPage(this.props);
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.match.url != nextProps.match.url) {
            this.getLandingPage(nextProps);
        }
    }

    getLandingPage = (props) => {
        try {
            let params = {
                // url: 'https://pages.soffront.com/Mzg3Myw1MTkw/fdghdfh',
                url: constants.landingPageEndPoint + props.match.url,
                decode_user_info: props.match.params.decodeuserinfo
            };
            let url = endPoint.publicfaceURL;
            var promise = new Promise((resolve, reject) => {
                axios.get(url, {
                    params: { input_param: params },
                }).then((resp) => {
                    resolve(resp.data);
                }).catch(err => {
                    reject(err);
                });
            });
            if (promise != null && promise != undefined) {
                promise.then((response) => {
                    if (response != null && response != undefined) {
                        if (response.status == -1) {
                            this.setState({ mounted: true, isValidURL: false });
                        }
                        else {
                            let data = response.data;
                            let html = data.body;
                            document.title = data.pageName;
                            this.setState({ body: html, mounted: true, projectId: data.projectId, userId: data.userId, landingPageId: data.landingPageId }, () => {
                                this.getRedirectUrl();
                                this.handleActions();

                            });
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'pageTemplatePrevies.js -> getLandingPage()':" + error);
        }
    }

    handleActions = () => {
        try {
            let hash = this.props.location.hash;
            if (hash) {
                window.history.pushState({}, "", "#" + this.props.location.pathname);
                let element = document.getElementById(hash.substring(1));
                if (element) {
                    setTimeout(function () {
                        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                    }, 1000);
                }
            }
        } catch (error) {
            console.error("Error in 'pageTemplatePrevies.js -> handleActions()':" + error);
        }
    }

    getRedirectUrl = () => {
        try {
            let redirectInfoDom = document.getElementsByClassName("sf-form-hidden-redirect-input");
            let redirectInfo = {};
            for (let index = 0; index < redirectInfoDom.length; index++) {
                let element = redirectInfoDom[index];
                redirectInfo = { ...redirectInfo, [element.name]: element.value }
            }
            if (redirectInfo.hasOwnProperty('websiteValue') && parseInt(redirectInfo.websiteValue) > 0) {
                let params = {
                    redirect_page_id: parseInt(redirectInfo.websiteValue),
                    decode_user_info: this.props.match.params.decodeuserinfo
                };
                let url = endPoint.getRedirectURL;
                var promise = new Promise((resolve, reject) => {
                    axios.get(url, {
                        params: { input_param: params },
                    }).then((resp) => {
                        resolve(resp.data);
                    }).catch(err => {
                        reject(err);
                    });
                });
                if (promise != null && promise != undefined) {
                    promise.then((response) => {
                        if (response != null && response != undefined) {
                            if (response.status != -1) {
                                let data = response.data;
                                this.setState({ redirectURL: data.redirect_url });
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'pageTemplatePrevies.js -> getRedirectUrl()':" + error);
        }
    }

    render() {
        if (this.state.mounted) {
            if (this.state.isValidURL) {
                return (
                    <div id="body" className="divBody">
                        <p dangerouslySetInnerHTML={{ __html: (this.state.body) }} />
                        <div><input type="hidden" id="projectId" name="projectId" value={this.state.projectId} /></div>
                        <div><input type="hidden" id="userId" name="userId" value={this.state.userId} /></div>
                        <div><input type="hidden" id="landingPageId" name="landingPageId" value={this.state.landingPageId} /></div>
                        <div><input type="hidden" id="redirectPageUrl" name="redirectURL" value={this.state.redirectURL} /></div>
                    </div>
                );
            } else {
                return (
                    <div id="body" className="divBody">
                        <PageNotFound />
                    </div>
                );
            }
        } else {
            return (
                <div className="row vertical-center">
                    <div className="sk-chase">
                        <div className="sk-chase-dot"></div><div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div><div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div><div className="sk-chase-dot"></div>
                    </div>
                </div>
            )
        }
    }
}

export default PageTemplatePreview;
